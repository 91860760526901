import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import StrategiModule from './Strategi';
import LawTrainingModule from './LawTraining';
import HealthTrainingModule from './HealthTraining';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/strategi" element={<StrategiModule />} />
        <Route path="/law" element={<LawTrainingModule />} />
        <Route path="/health" element={<HealthTrainingModule />} />
      </Routes>
    </Router>
  );
}

export default App;