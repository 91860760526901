import React from 'react';
import IntegratedTrainingModule from './Landing';

const LawTrainingModule = () => {
    const lawScenarios = [
        {
          title: "Initial Consultation",
          subtitle: "Client Interview and Case Evaluation",
          description: "Emma Parker, a 35-year-old marketing executive, is seeking legal advice regarding a potential medical malpractice case involving her 8-year-old daughter, Sophia, who experienced severe complications following a routine tonsillectomy.",
          concepts: [
            "Conducting initial client consultations",
            "Explaining medical malpractice elements",
            "Identifying relevant documentation",
            "Outlining the legal process",
            "Discussing potential outcomes and compensation",
            "Explaining contingency fees",
            "Managing client expectations"
          ],
          agentId: "agent_261eb630317e13277eec1ab2ca",
          promptName: "generate_call_analysis_law_consultation"
        },
        {
          title: "Expert Witness Preparation",
          subtitle: "Preparing Dr. Emily Rodriguez for Deposition",
          description: "You are meeting with Dr. Emily Rodriguez, a board-certified pediatric otolaryngologist, to prepare her for her upcoming deposition as a key expert witness in the Parker case.",
          concepts: [
            "Understanding Ohio Revised Code 2743.43",
            "Explaining deposition procedures",
            "Articulating standard of care",
            "Establishing clear causation",
            "Handling questions outside expertise",
            "Ensuring objectivity and ethical considerations",
            "Relating expert credentials to legal requirements"
          ],
          agentId: "agent_c81b9c8f4a1f9be5fa4d48f3bb",
          promptName: "generate_call_analysis_law_expert_witness"
        },
        {
          title: "Pre-litigation Negotiation",
          subtitle: "Negotiating with the Hospital's Insurance Company",
          description: "You are entering pre-litigation negotiations with Michael Thompson, the insurance adjuster for Midwest Community Hospital, to potentially settle the Parker case before a lawsuit is filed.",
          concepts: [
            "Pre-litigation negotiation tactics",
            "Assessing damages in medical malpractice",
            "Applying Ohio's damage caps",
            "Developing settlement demands",
            "Responding to insurance adjuster tactics",
            "Balancing client interests with litigation risks",
            "Ethical negotiation practices"
          ],
          agentId: "agent_2bbd4cf09d797535958285fabf",
          promptName: "generate_call_analysis_law_negotiation"
        },
        {
          title: "Deposition",
          subtitle: "Deposing Dr. James Henderson",
          description: "You are tasked with deposing Dr. James Henderson, the otolaryngologist who performed the tonsillectomy on Sophia Parker. Dr. Henderson is known to be defensive and uncooperative.",
          concepts: [
            "Deposition techniques for hostile witnesses",
            "Understanding of tonsillectomy procedures",
            "Articulating informed consent requirements",
            "Handling attempts to derail the deposition",
            "Maintaining professional composure",
            "Redirecting conversations effectively",
            "Aligning questioning with case goals"
          ],
          agentId: "agent_b7a0b40eac4c988c20600586ef",
          promptName: "generate_call_analysis_law_deposition"
        }
      ];
    
      return (
        <IntegratedTrainingModule
          sector="law"
          logo="/law.png"
          title="Parker v. Midwest Community Hospital"
          subtitle="Simulated Training"
          description="You have been assigned to handle the pre-litigation negotiation in the case of Parker v. Midwest Community Hospital. Our clients are Mark and Emma Parker, parents of Sophia Parker (age 8). Sophia underwent a routine tonsillectomy at Midwest Community Hospital on June 15, 2023. Following the surgery, Sophia experienced severe post-operative bleeding, leading to emergency hospitalization and long-term complications. The following scenarios are designed to prepare you for the consultation, witness preparation, pre-litigation negotiation, and the deposition stage of the litigation process."
          scenarios={lawScenarios}
        />
      );
    };

export default LawTrainingModule;