import React, { useState, useEffect, useCallback, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronDown, ChevronUp, BookOpen, Phone, X, RefreshCw, Mail } from 'lucide-react';
import { RetellWebClient } from "retell-client-js-sdk";
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import { useNavigate } from 'react-router-dom';
// import SentimentAnalysis from './Sentiment';

const ScenarioCard = ({ title, subtitle, description, concepts, onLaunch }) => {
    const [isExpanded, setIsExpanded] = useState(false);
  
    return (
      <motion.div
        className="bg-white/10 backdrop-blur-lg text-white rounded-lg shadow-lg overflow-hidden mb-6"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <div
          className="flex justify-between items-center p-4 cursor-pointer bg-[#4c8bf5] text-white"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <h3 className="text-xl font-semibold">{title}</h3>
          {isExpanded ? <ChevronUp size={24} /> : <ChevronDown size={24} />}
        </div>
        {isExpanded && (
          <div className="p-4">
            <h4 className="text-lg font-medium text-blue-300 mb-2">{subtitle}</h4>
            <p className="mb-4">{description}</p>
            <h5 className="font-semibold mb-2">Core Concepts:</h5>
            <ul className="list-disc pl-5 mb-4">
              {concepts.map((concept, index) => (
                <li key={index}>{concept}</li>
              ))}
            </ul>
            <button
              onClick={onLaunch}
              className="bg-[#4c8bf5] hover:bg-[#3b7de8] text-white px-6 py-2 rounded-full font-semibold transition-all duration-300 flex items-center"
            >
              <BookOpen size={20} className="mr-2" />
              Launch Simulation
            </button>
          </div>
        )}
      </motion.div>
    );
  };

  const ActiveCall = ({ scenarioTitle, status, onEndCall }) => {
    return (
      <motion.div 
        className="bg-white/10 backdrop-blur-lg text-white rounded-lg shadow-lg p-6 w-full max-w-md"
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.9 }}
      >
        <h2 className="text-xl font-bold mb-4">{scenarioTitle}</h2>
        <div className="flex items-center justify-center mb-4">
          <div className="w-12 h-12 bg-blue-500 rounded-full flex items-center justify-center mr-4">
            <Phone size={24} />
          </div>
          <div className="text-lg font-semibold">Active Call</div>
        </div>
        <div className="text-center mb-4">
          <p className="text-base mb-1">Status: {status}</p>
          <p className="text-sm text-gray-300">
            Your responses are being recorded and analyzed.
          </p>
        </div>
        <button
          onClick={onEndCall}
          className="w-full bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-full font-semibold transition-all duration-300 flex items-center justify-center"
        >
          <X size={16} className="mr-2" />
          End Call
        </button>
      </motion.div>
    );
  };

  const TranscriptSection = ({ transcript, onRetry, isLoading }) => {
    return (
      <motion.div 
        className="w-1/2 pr-2"
        initial={{ opacity: 0, x: -50 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.5 }}
      >
        <div className="flex justify-between items-center mb-2">
          <h3 className="text-lg font-semibold text-blue-300">Transcript</h3>
          <button 
            onClick={onRetry} 
            className="text-blue-300 hover:text-blue-100 focus:outline-none"
            disabled={isLoading}
          >
            <RefreshCw size={20} className={isLoading ? "animate-spin" : ""} />
          </button>
        </div>
        <motion.div 
          className="bg-[#002a47] p-4 rounded-md h-[70vh] overflow-y-auto"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2, duration: 0.5 }}
        >
          {isLoading ? (
            <p className="text-blue-200 italic">Loading transcript...</p>
          ) : transcript ? (
            transcript.split('\n').map((line, index) => (
              <motion.p 
                key={index} 
                className="mb-2 text-white"
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.05, duration: 0.3 }}
              >
                {line}
              </motion.p>
            ))
          ) : (
            <p className="text-blue-200 italic">Transcript not available. Please try again.</p>
          )}
        </motion.div>
      </motion.div>
    );
  };
  
  const AnalysisSection = ({ analysis, onRetry, isLoading }) => {
    const analysisRef = useRef(null);
  
    useEffect(() => {
      if (analysisRef.current) {
        analysisRef.current.scrollTop = analysisRef.current.scrollHeight;
      }
    }, [analysis]);
  
    return (
      <motion.div 
        className="w-1/2 pl-2"
        initial={{ opacity: 0, x: 50 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.5 }}
      >
        <div className="flex justify-between items-center mb-2">
          <h3 className="text-lg font-semibold text-blue-300">Call Analysis</h3>
          <button 
            onClick={onRetry} 
            className="text-blue-300 hover:text-blue-100 focus:outline-none"
            disabled={isLoading}
          >
            <RefreshCw size={20} className={isLoading ? "animate-spin" : ""} />
          </button>
        </div>
        <motion.div 
          ref={analysisRef}
          className="bg-[#002a47] p-4 rounded-md h-[70vh] overflow-y-auto"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2, duration: 0.5 }}
        >
          {isLoading && analysis.length === 0 ? (
            <p className="text-blue-200 italic">Generating analysis...</p>
          ) : (
            <ReactMarkdown 
              rehypePlugins={[rehypeRaw]} 
              remarkPlugins={[remarkGfm]}
              className="text-white"
            >
              {analysis || 'Analysis not available. Please try again.'}
            </ReactMarkdown>
          )}
        </motion.div>
      </motion.div>
    );
  };

  const CallDetails = ({ callId, promptName, onStartNewScenario }) => {
    const [transcript, setTranscript] = useState(null);
    const [analysis, setAnalysis] = useState('');
    // const [sentimentData, setSentimentData] = useState(null);
    const [transcriptLoading, setTranscriptLoading] = useState(true);
    const [analysisLoading, setAnalysisLoading] = useState(true);
    // const [sentimentLoading, setSentimentLoading] = useState(true);
    // const [sentimentError, setSentimentError] = useState(null);
    const abortControllerRef = useRef(null);
  
    const fetchTranscript = async () => {
      setTranscriptLoading(true);
      try {
        const response = await fetch(`https://schooljoy-staging.eastus2.cloudapp.azure.com/get_retell_call_details?call_id=${callId}`);
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(`Failed to fetch call details: ${JSON.stringify(errorData)}`);
        }
        const data1 = await response.json();
        const data = JSON.parse(data1);
        console.log('Received call details:', data);
        setTranscript(data.transcript);
      } catch (error) {
        console.error('Error fetching transcript:', error);
        setTranscript(null);
      } finally {
        setTranscriptLoading(false);
      }
    };
  
    const fetchAnalysis = useCallback(async () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      abortControllerRef.current = new AbortController();
  
      setAnalysisLoading(true);
      setAnalysis('');
      try {
        const response = await fetch(
          `https://schooljoy-staging.eastus2.cloudapp.azure.com/generate_call_analysis?call_id=${callId}&prompt_name=${promptName}`,
          { signal: abortControllerRef.current.signal }
        );
        const reader = response.body.getReader();
        const decoder = new TextDecoder();
  
        while (true) {
          const { value, done } = await reader.read();
          if (done) break;
          const chunk = decoder.decode(value);
          setAnalysis(prevAnalysis => prevAnalysis + chunk);
        }
      } catch (error) {
        if (error.name === 'AbortError') {
          console.log('Fetch aborted');
        } else {
          console.error('Error fetching call analysis:', error);
          setAnalysis('Error: Failed to fetch call analysis');
        }
      } finally {
        setAnalysisLoading(false);
      }
    }, [callId, promptName]);

    // const fetchSentimentAnalysis = useCallback(async () => {
    //   setSentimentLoading(true);
    //   setSentimentData(null);
    //   setSentimentError(null);
    //   try {
    //     const startResponse = await fetch('https://schooljoy-staging.eastus2.cloudapp.azure.com/start_hume_sentiment_analysis', {
    //       method: 'POST',
    //       headers: { 'Content-Type': 'application/json' },
    //       body: JSON.stringify({ call_id: callId })
    //     });
        
    //     if (!startResponse.ok) {
    //       throw new Error(`HTTP error! status: ${startResponse.status}`);
    //     }
        
    //     const { hume_job_id } = await startResponse.json();
  
    //     const pollForResults = async () => {
    //       try {
    //         const pollResponse = await fetch(`https://schooljoy-staging.eastus2.cloudapp.azure.com/sentiment_analysis/${hume_job_id}`);
    //         if (!pollResponse.ok) {
    //           throw new Error(`HTTP error! status: ${pollResponse.status}`);
    //         }
    //         const data = await pollResponse.json();
  
    //         if (Array.isArray(data) && data.length > 0 && data[0].results) {
    //           // Results are ready
    //           setSentimentData(data);
    //           setSentimentLoading(false);
    //         } else if (data.status === 'IN_PROGRESS') {
    //           // Continue polling
    //           setTimeout(pollForResults, 2000);
    //         } else {
    //           // Unexpected response format
    //           throw new Error('Unexpected response format');
    //         }
    //       } catch (error) {
    //         console.error('Error polling for results:', error);
    //         setSentimentError(`Error polling for results: ${error.message}`);
    //         setSentimentLoading(false);
    //       }
    //     };
  
    //     pollForResults();
  
    //   } catch (error) {
    //     console.error('Error fetching sentiment analysis:', error);
    //     setSentimentError("An error occurred while fetching sentiment analysis. Please try again.");
    //     setSentimentLoading(false);
    //   }
    // }, [callId]);
  
    useEffect(() => {
      if (callId) {
        const timer = setTimeout(() => {
          fetchTranscript();
          fetchAnalysis();
        }, 700);
  
        // Delay sentiment analysis by 2 seconds
        // const sentimentTimer = setTimeout(() => {
        //   fetchSentimentAnalysis();
        // }, 2000);
  
        return () => {
          clearTimeout(timer);
          // clearTimeout(sentimentTimer);
          if (abortControllerRef.current) {
            abortControllerRef.current.abort();
          }
        };
      }
    }, [callId, promptName, fetchAnalysis]);

    // console.log(sentimentData);

    return (
      <motion.div 
        className="mt-8 bg-[#001a2c] p-6 rounded-lg shadow-md w-full max-w-6xl"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h2 className="text-2xl font-bold mb-6 text-center text-blue-300">Call Results</h2>
        
        <div className="flex space-x-4">
          <TranscriptSection 
            transcript={transcript} 
            onRetry={fetchTranscript}
            isLoading={transcriptLoading}
          />
          <AnalysisSection 
            analysis={analysis} 
            onRetry={fetchAnalysis}
            isLoading={analysisLoading}
          />
        </div>
        
        {/* <div className="mt-8">
          <SentimentAnalysis 
            sentimentData={sentimentData} 
            isLoading={sentimentLoading} 
            onRetry={fetchSentimentAnalysis}
            error={sentimentError}
          />
        </div> */}
        
        <motion.div 
          className="mt-8 flex justify-center"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.5, duration: 0.5 }}
        >
          <motion.button
            onClick={onStartNewScenario}
            className="px-6 py-3 bg-[#4c8bf5] hover:bg-[#3b7de8] text-white font-semibold rounded-full shadow-md"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Back to Scenarios
          </motion.button>
        </motion.div>
      </motion.div>
    );
  };

  const IntegratedTrainingModule = ({ 
    sector,
    logo,
    title,
    subtitle,
    description,
    scenarios
  }) => {
    const [activeScenario, setActiveScenario] = useState(null);
    const [status, setStatus] = useState('Ready to start the call');
    const [retellWebClient, setRetellWebClient] = useState(null);
    const [callId, setCallId] = useState(null);
    const [promptName, setPromptName] = useState(null);
    const [currentView, setCurrentView] = useState('selection');


    const navigate = useNavigate();
  
    useEffect(() => {
      const client = new RetellWebClient();
      setRetellWebClient(client);
  
      const handleCallStarted = () => {
        console.log('Call started');
        setStatus('Call in progress');
      };
  
      const handleCallEnded = () => {
        console.log('Call ended by agent or system');
        setStatus('Call ended');
        setCurrentView('results');
      };
  
      const handleDisconnect = (data) => {
        console.log('Disconnected from room:', data);
        setStatus('Call disconnected');
        handleCallEnded(); // Trigger the same actions as call_ended
      };
  
      const handleError = (error) => {
        console.error('An error occurred:', error);
        setStatus(`Error: ${error.message}`);
        setActiveScenario(null);
        setCurrentView('selection');
      };
  
      client.on('call_started', handleCallStarted);
      client.on('call_ended', handleCallEnded);
      client.on('disconnect', handleDisconnect);
      client.on('error', handleError);
  
      return () => {
        client.off('call_started', handleCallStarted);
        client.off('call_ended', handleCallEnded);
        client.off('disconnect', handleDisconnect);
        client.off('error', handleError);
      };
    }, []);
  
    const startCall = async (scenarioTitle, agentId, promptName) => {
      try {
        setStatus('Starting the call...');
    
        const response = await fetch('https://schooljoy-staging.eastus2.cloudapp.azure.com/create-web-call/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            agent_id: agentId,
          }),
        });
    
        if (!response.ok) {
          const errorText = await response.json();
          throw new Error(`Server Error: ${response.status} - ${errorText}`);
        }
    
        const responseText = await response.json();
        console.log('Server response:', responseText);
        const createCallResponse = JSON.parse(responseText);
        console.log('Parsed response:', createCallResponse);
        const { access_token: accessToken, call_id: newCallId } = createCallResponse;
    
        if (!accessToken) {
          throw new Error('No access token received from server.');
        }
    
        await retellWebClient.startCall({ accessToken });
        console.log(`Call started - ID: ${newCallId}`);
        setStatus(`Call in progress`);
        setActiveScenario(scenarioTitle);
        setCallId(newCallId);
        setPromptName(promptName);
        setCurrentView('call');
      } catch (error) {
        console.error('Error starting the call:', error);
        setStatus(`Failed to start the call`);
      }
    };
  
    const endCall = () => {
      if (retellWebClient) {
        retellWebClient.stopCall();
        setStatus('Ending call...');
      }
    };
  
    const handleStartNewScenario = () => {
      setActiveScenario(null);
      setCallId(null);
      setPromptName(null);
      setStatus('Ready to start the call');
      setCurrentView('selection');
      navigate(`/${sector}`);
    };
  
  
    return (
      <div className="min-h-screen bg-[#001a2c] text-white flex flex-col">
        <div className="fixed inset-0 bg-gradient-to-b from-[#001a2c] to-[#00284a] -z-10"></div>
        
        <div className="flex-grow flex flex-col items-center justify-start p-8 overflow-y-auto">
          <motion.img
            src={logo}
            alt={`${sector} Logo`}
            className="w-15 h-15 mb-8"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, ease: "easeOut" }}
          />
          
          <AnimatePresence mode="wait">
            {currentView === 'selection' && (
              <motion.div
                key="scenario-selection"
                className="w-full max-w-4xl"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
              >
                <motion.h1
                  className="text-4xl md:text-5xl font-bold mb-4 text-center leading-tight"
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.2, duration: 1 }}
                >
                  {title}
                </motion.h1>
                <motion.h2
                  className="text-2xl md:text-3xl font-semibold mb-4 text-center text-blue-300"
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.4, duration: 1 }}
                >
                  {subtitle}
                </motion.h2>
                <motion.p
                  className="text-lg mb-12 text-center text-blue-200"
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.8, duration: 1 }}
                >
                  {description}
                </motion.p>
                
                {scenarios.map((scenario, index) => (
                  <ScenarioCard
                    key={index}
                    title={scenario.title}
                    subtitle={scenario.subtitle}
                    description={scenario.description}
                    concepts={scenario.concepts}
                    onLaunch={() => startCall(scenario.title, scenario.agentId, scenario.promptName)}
                  />
                ))}
              </motion.div>
            )}
            {currentView === 'call' && (
              <motion.div
                key="active-call"
                className="flex-grow flex items-center justify-center w-full"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
              >
                <ActiveCall
                  scenarioTitle={activeScenario}
                  status={status}
                  onEndCall={endCall}
                />
              </motion.div>
            )}
            {currentView === 'results' && (
              <motion.div
                key="call-results"
                className="w-full max-w-6xl"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
              >
                <CallDetails
                  callId={callId}
                  promptName={promptName}
                  onStartNewScenario={handleStartNewScenario}
                />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
        
        <motion.div
          className="p-4 w-full text-center text-sm text-blue-200"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 1, duration: 1 }}
        >
          © 2024 {sector} Compliance. All rights reserved.
        </motion.div>
      </div>
    );
  };
    
export default IntegratedTrainingModule;