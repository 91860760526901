import React from 'react';
import IntegratedTrainingModule from './Landing';

const StrategiModule = () => {
  const strategiScenarios = [
    {
      title: "The Smith Family Estate",
      subtitle: "Navigating Multi-Owner Residential Sales",
      description: "Sarah Smith contacts you about selling a valuable family property in Remuera, Auckland. The property is co-owned by Sarah and her two siblings, presenting challenges in coordination and decision-making.",
      concepts: [
        "Multi-owner property transactions",
        "Handling overseas property owners",
        "Agency agreements for co-owned properties",
        "Strategies for reluctant co-owners",
        "Ethical considerations in family property sales",
        "Property valuation in premium markets",
        "Documentation requirements for co-owned properties"
      ],
      agentId: "agent_28aae7c1b1700f78fd2f24f78f",
      promptName: "generate_call_analysis_smith_family"
    },
    {
      title: "TechCorp's Downtown Divestment",
      subtitle: "Managing Corporate Property Transactions",
      description: "Alex Chen, CFO of TechCorp, approaches you to sell their large office building in Auckland's CBD. As a publicly-traded company, TechCorp has specific requirements and constraints.",
      concepts: [
        "Corporate property sales process",
        "Compliance with NZX regulations for publicly-traded companies",
        "Documentation for corporate property transactions",
        "Market analysis for commercial real estate",
        "Confidentiality in high-profile sales",
        "Agency agreements for corporate clients",
        "Marketing strategies for high-value commercial properties",
        "Legal and financial considerations in corporate real estate"
      ],
      agentId: "agent_8992ce001e8a45decea80444a1",
      promptName: "generate_call_analysis_techcorp"
    }
  ];

  return (
    <IntegratedTrainingModule
      sector="strategi"
      logo="/logo-strategi.png"
      title="Marketing, qualifying customers, and presenting properties"
      subtitle="Simulated Training"
      description="New Zealand Certificate in Real Estate (Salesperson) Level 4 Unit standard 23140"
      scenarios={strategiScenarios}
    />
  );
};

export default StrategiModule;